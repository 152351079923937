import {MemberPermission} from '~/utils/permissions'

const routes = [
    // {
    //     path: '/members',
    //     name: 'member-list',
    //     component: () => import(/* webpackChunkName: "member" */ '~/views/member/member-list.vue'),
    //     meta: {
    //         permission: MemberPermission.ViewMemberData
    //     }
    // },
    // {
    //     path: '/member/:id',
    //     name: 'member',
    //     component: () => import(/* webpackChunkName: "member" */ '~/views/member/member.vue'),
    //     meta: {
    //         permission: MemberPermission.ViewMemberData
    //     }
    // },
    {
        path: '/verification-list',
        name: 'verification-list',
        component: () => import(/* webpackChunkName: "member" */ '~/views/member/verification-list.vue'),
        meta: {
            permission: MemberPermission.Verifications
        }
    }
]

export default routes
