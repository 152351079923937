import {AdminPermission} from '~/utils/permissions'
import {LogPermission, ProofFactorPermission, ShortenLinkPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/event',
        name: 'event',
        component: () => import(/* webpackChunkName: "event" */ '~/views/event.vue'),
        meta: {
            permission: AdminPermission.Event
        }
    },
    {
        path: '/shorten-links',
        name: 'shorten-links',
        component: () => import(/* webpackChunkName: "shorten-links" */ '~/views/shorten-link/shorten-links-manager.vue'),
        meta: {
            permission: ShortenLinkPermission.View
        }
    },
    {
        path: '/proof-factor',
        name: 'proof-factor',
        component: () => import(/* webpackChunkName: "proof-factor" */ '~/views/proof-factor/proof-factor-hooks-manager.vue'),
        meta: {
            permission: ProofFactorPermission.View
        }
    }
]

export default routes
